import React, { useEffect } from "react";
import Image from "gatsby-image";
import { Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import SEO from "../components/seo";
import Particles from "react-particles-js";
import particlesConfig from "../vendor/particles.json";
import WidgetBot from "@widgetbot/react-embed";

/* Isolate Page
============================================================================= */

const IsolatePage = ({ data }) => {
	// Config

	// Render
	return (
		<>
			<SEO title="Iso-Late" />
			<div className="w-full">
				<div className="w-full bg-black lg:px-32 md:px-16 px-0 md:py-32 py-0 flex flex-row items-center justify-center">
					<div className="absolute w-full h-full top-0 left-0 md:block hidden">
						<Particles params={particlesConfig} />
					</div>
					<div className="container w-full flex flex-row items-center lg:justify-center justify-start lg:h-180 lg:py-0 py-5">
						<div
							className="w-full h-0 relative"
							style={{ paddingBottom: "56.25%" }}
						>
							<iframe
								src="https://player.twitch.tv/?channel=isolaidfestival&parent=www.isolaidfestival.com"
								allowFullScreen
								className="w-full h-full absolute"
							></iframe>
						</div>
					</div>
				</div>
			</div>
			<div className="overflow-hidden w-full bg-black lg:px-32 md:px-16 px-0 md:py-20 py-0 flex flex-row items-center justify-center relative h-auto">
				<div className="absolute w-full top-0 bottom-0 left-0 right-0 h-full opacity-70">
					<Image
						fluid={data.triangles.childImageSharp.fluid}
						className="w-full"
						imgStyle={{
							objectFit: "cover",
							objectPosition: "50% 50%",
						}}
					/>
				</div>
				<div className="container w-full flex flex-col items-center justify-start lg:py-0 py-5">
					<div className="w-32 px-2">
						<Image
							fluid={data.goldseal.childImageSharp.fluid}
							className="w-full h-full"
							imgStyle={{
								objectFit: "contain",
								objectPosition: "50% 50%",
							}}
						/>
					</div>
					<div className="w-96 mt-4 px-4">
						<Image
							fluid={data.presents.childImageSharp.fluid}
							className="w-full h-full"
							imgStyle={{
								objectFit: "contain",
								objectPosition: "50% 50%",
							}}
						/>
					</div>
					<div className="w-96 mb-10 px-4">
						<Image
							fluid={data.long.childImageSharp.fluid}
							className="w-full h-full"
							imgStyle={{
								objectFit: "contain",
								objectPosition: "50% 50%",
							}}
						/>
					</div>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						<strong>ISO-LATE #4</strong> at your service. The
						bangers keep banging and the hits keep making you hit
						the living room dance floor. We LOVE partying with you.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						This week heavy hitters in lieu of heavy petting, radio
						host, DJ and music producer,{" "}
						<strong>NINA LAS VEGAS</strong>. Over the last decade
						this one-woman can-do queen has been forging her path as
						one of the most influential women in the music industry,
						passionately dedicating herself to promoting and
						celebrating the sounds of underground dance music from
						both Australia and abroad. This dial is turned all the
						way up to HOT when this tastemaker spins her tracks.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						Melbourne-based producer and DJ <strong>BENSON</strong>{" "}
						whose infamy is almost larger than his instagram
						following, is known for his brilliant taste in tunes and
						DJ antics being loud, fun and up to no bloody good. And
						as if number one naughty boy on the decks isn’t enough,
						catch him as part of Iso-Late #4 with partner in crime,{" "}
						<strong>STACE CADET</strong>.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						Fellow Melbournite, tech-house favourite{" "}
						<strong>STACE CADET</strong>, whose ‘Energy’ record with
						KLP is doing big numbers all over the place, focuses his
						energy (har har) on crafting low-end basslines that’ll
						bounce through your speakers and onto the dancefloor.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						<strong>BENSON V STACE CADET.</strong> Battle of the
						beats. Battle of the antics. Battle of the moustaches.
						Don’t. Miss. This.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						And, also hailing from Melbourne, Iso-Late is proud to
						present DJ, producer, event curator and presenter,{" "}
						<strong>JADE ZOE</strong>, known for her genre-bending
						and dance floor slamming sets that melt R&B, trap, hip
						hop, dancehall, grime, pop and everything in between
						(and on the edges).
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						So boogie with your buddies, hold your honeys, or
						comment with your cuties, your Saturday night selectors
						are sorted.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						One Stage. One Stream. Party Together. Save The Night.
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						Jägermeister presents
					</p>
					<p className="text-white ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						ISO-LATE #4: Saturday 6th June 10pm-midnight
					</p>
					<p className="text-white font-black ibm-plex lg:w-3/5 w-full text-center pb-8 text-lg leading-loose lg:px-0 px-8">
						Performing late and live:
					</p>
					<p className="text-white  font-black ibm-plex lg:w-3/5 w-full text-center pb-8 text-3xl leading-loose lg:px-0 px-8">
						NINA LAS VEGAS
					</p>
					<p className="text-white  font-black ibm-plex lg:w-3/5 w-full text-center pb-8 text-3xl leading-loose lg:px-0 px-8">
						BENSON VS STACE CADET
					</p>
					<p className="text-white  font-black ibm-plex lg:w-3/5 w-full text-center pb-8 text-3xl leading-loose lg:px-0 px-8">
						JADE ZOE
					</p>
					<p className="text-white font-black ibm-plex lg:w-3/5 w-full text-center pb-8 text-3xl leading-loose lg:px-0 px-8">
						Intermissions by THE HUXLEYS & TANZER
					</p>
				</div>
			</div>
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query IsolatePageQuery {
		goldseal: file(relativePath: { eq: "GoldSeal.png" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		presents: file(relativePath: { eq: "Presents.png" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		triangles: file(relativePath: { eq: "IsolateTriangleBackground.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		long: file(relativePath: { eq: "IsolateLong.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default IsolatePage;
